<template>
  <q-form
    ref="passForm"
    @submit="onSubmit"
  >
    <q-card-section>
      <div class="q-pa-sm">
        <h5 class="q-mb-none q-mt-xs text-weight-bold">
          Reset Password
        </h5>
      </div>
    </q-card-section>

    <q-card-section
      v-if="!!serverErrorMessage"
      class="q-py-none"
    >
      <div
        class="q-px-md q-py-sm text-red"
        inset
      >
        {{ serverErrorMessage }}
      </div>
    </q-card-section>

    <q-card-section>
      <div class="q-pa-sm q-gutter-y-sm column">
        <q-input
          ref="passField"
          v-model="password"
          :type="isPwd ? 'password' : 'text'"
          label="Password"
          :rules="[val => !!val || 'Field is required', val => val.length >= 6 || 'Field is too short']"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>

        <q-input
          v-model="password_cfm"
          :type="isPwd ? 'password' : 'text'"
          label="Confirm Password"
          :rules="[
            val => !!val || 'Field is required',
            val => val.length >= 6 || 'Field is too short',
            val => val == password || 'Field must martch password field',
          ]"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>

        <q-btn
          type="submit"
          color="primary"
          no-caps
          :loading="loading"
        >
          Save Password
        </q-btn>
      </div>
    </q-card-section>

    <q-separator />

    <q-card-actions align="between">
      <q-btn
        to="/auth/login"
        color="secondary"
        flat
        no-caps
      >
        Log In
      </q-btn>
      <q-btn
        to="/auth/forgot"
        color="secondary"
        flat
        no-caps
      >
        Forgot Password
      </q-btn>
    </q-card-actions>
  </q-form>
</template>

<script>
import { serverErrorMixin } from 'src/mixins/server_error.mixin';
import { ApiService } from 'src/services/api.service';
import { AuthActionTypes } from 'src/modules/auth/store/action-types';

export default {
  mixins: [serverErrorMixin],
  props: {
    uid: {
      type: String,
      default: null,
      required: true,
    },
    secs: {
      type: String,
      default: null,
      required: true,
    },
    chk: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      password: null,
      password_cfm: null,
      isPwd: true,
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      try {
        const apiData = await ApiService.post(`/jwt/auth/resetpass/${this.uid}/${this.secs}/${this.chk}`, {
          password: this.password,
          password_cfm: this.password_cfm,
        });

        this.$store.dispatch(`auth/${AuthActionTypes.AuthLoginSuccess}`, apiData.data);

        this.$router.push('/');

        return true;
      } catch (err) {
        if (err.response) {
          this.serverErrorMessage = err.response.data.message;

          if (err.response.data.errors) {
            this.serverErrors = err.response.data.errors;
          } else {
            console.log('no serverErrors');
          }
          this.$refs.forgotForm.validate();
        } else {
          console.log('Error: ', err.message);
          throw err;
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
